<template>
  <div class="mt-12">
    <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
        <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
          Expense Categories ({{totalCount}})
        </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Code...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="categoryList.length > 0">
          <div v-if="!mobileView">
            <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
              <div class="column_size uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(0)">{{ titleObj[0].title }}</span>
                <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
              </div>
              <div class="column_size uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(1)">{{ titleObj[1].title }}</span>
                <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
              </div>
              <div class="column_size uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''">
                <span class="cursor-pointer" @click="sortByColumn(2)">{{ titleObj[2].title }}</span>
                <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
              </div>
            </div>
            <div v-if="categoryList.length > 0">
              <div v-for="(data, index) in categoryList" :key="index"
                class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer"
                @click="openDetail(data.codeId, data)">
                <div class="column_size  ">
                  <div class="">
                    <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                    <p class="text-gray4">{{ data.codeName }}</p>
                  </div>
                </div>
                <div class="column_size  ">
                  <div class="flex items-center">
                    <span class="  text-gray4 heading-6" v-if="data.addedDate !== ''">{{ data.addedDate | formatDataeTime}}</span>
                    <span class="  text-gray4 heading-6" v-else>-</span>
                  </div>
                </div>
                <div class="column_size">
                  <div class="flex items-center">
                    <span class="  text-gray4 heading-6" v-if="data.updatedDate !== ''">{{ data.updatedDate  | formatDataeTime}}</span>
                    <span class="  text-gray4 heading-6" v-else>-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="mobileView">
            <div style="overflow-y: auto; white-space: nowrap;" class="" v-if="categoryList.length > 0">
              <div v-for="(data, index) in categoryList" :key="index"  class="cust_card m-2  heading-3 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openDetail(data.codeId, data)">
                <div class="items-center">
                  <p class="text-text2 heading-5 font-semibold">{{data.codeName}}</p>
                  <div class="flex items-center">
                    <p class="w-32 text-gray4 heading-5">Added Date:</p>
                    <p class="text-gray4 heading-5" v-if="data.addedDate !== ''">{{data.addedDate | formatDataeTime}}</p>
                    <p class="text-gray4 heading-5" v-else>-</p>
                  </div>
                  <div class="flex items-center">
                    <p class="w-32 text-gray4 heading-5">Updated On:</p>
                    <p class="text-gray4 heading-5" v-if="data.updatedDate !== ''">{{data.updatedDate | formatDataeTime}}</p>
                    <p class="text-gray4 heading-5" v-else>-</p>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div>
            <Pagination :totalDataCount="totalCount" :resetPagination="resetPaginationValues"
            @callApiAsPerPagination="getLeadDataFromPagination" />
          </div>
        </div>
        <div v-if="totalCount === 0">
          <NoRecords :alertMessage="`No records found.`" />
        </div> 
      </div>
    </div>
    <AddUpdateCode v-if="addCode" :codeId="selectedCatId" :object="selectedDetail" />
   </div>
</template>
<script>
import AddUpdateCode from './addEditCode.vue'
import Pagination from '@/View/components/pagination.vue'
import NoRecords from '@/View/components/noRecords.vue'
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    SearchTextInput,
    NoRecords,
    Button,
    AddUpdateCode,
    Pagination,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        { title: 'Code', icon: '', sortName: 'codeName' },
        { title: 'Added Date', icon: '', sortName: 'addedDate' },
        { title: 'Updated On', icon: '', sortName: 'updatedDate' },
      ],
      mobileView: false,
      categoryList: [],
      searchForLead: '',
      getCodeDebounce: null,
      resetPaginationValues: false,
      addCode: false,
      totalCount: 0,
      selectedCatId: 0,
      selectedDetail: {},
      filterObject: {
        start: 0,
        length: 20,
        entStatusList: [],
        empStatusList: [],
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'addedDate',
        statusList: []
      },
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    this.resizeWindowHandler()
    document.body.style = 'overflow: visible;'
    this.getCodeListing()
    this.getCodeDebounce = this.debounce(function () {
      this.getCodeListing()
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addEditCodeComp", (response) => {
      console.log(response);
      if (response) {
        this.getCodeListing()
        this.addCode = false
      }
      this.addCode = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
    this.$root.$off('addEditCodeComp')
  },
  methods: {
    openDetail (Id, data) {
      console.log('Id', Id)
      this.selectedCatId = Id
      this.selectedDetail = data
      this.addCode= true
    },
    addNewCust () {
      this.selectedCatId = 0
      this.selectedDetail = {}
      this.addCode= true
    },
    getLeadDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getCodeListing(true)
    },
    searchCall (data) {
      this.searchForLead = data
      this.getCodeDebounce()
    },
    sortByColumn(index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getCodeListing(true)
    },
    getCodeListing (showlistLoader) {
      if (showlistLoader && this.searchForLead !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForLead === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.getAccountingCodeList(
          this.filterObject.start,
          this.filterObject.length,
          this.searchForLead,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          response => {
            this.categoryList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.totalCount = response.Data.count
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    },
    resizeWindowHandler() {
      if (window.innerWidth < 800) {
        this.mobileView = true
      } else {
        this.mobileView = false
      }
    },
  },
};
</script>
<style scoped>
.column_size {
  min-width: 400px !important;
  width: 400px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>