<template>
  <div class="popup_overlay px-4">
  <div class="custom_dialog rounded-xl" id="itemdialogs" style="width: 600px;max-height: 85%;">
    <div class="relative bg-white">
      <div class="bg-primary flex justify-between p-3 sticky top-0">
        <div class=" font-medium text-white heading-3">
          {{codeId > 0 ? 'Update Expense Category' : 'Add New Expense Category'}}
        </div>
        <div v-if="codeId > 0">
          <div class=" text-error heading-5 cursor-pointer bg-white px-2.5 py-1 rounded-xl" @click="removeConfirmCheck">
            <i class="fa-solid fa-trash"></i>
          </div>
        </div>
      </div>
      <div style="max-height: 60vh" class="overflow-auto">
        <div class="p-4">
            <div class="mb-4">
              <TextField
              :inputId="'testInput21'"
              :inputext="CodeNameVal"
              :placholderText="`Expense Category name`"
              :lableText="'Expense Category name'"
              :inputType="'text'"
              :textMaxlength="50"
              :showcharLimit="true"
              :fieldError="nameErr !== ''"
              :autoFocus="false"
              @keyPressAction="nameErr = ''"
              @inputChangeAction="(data) => CodeNameVal = data"  />
              <p v-if="nameErr !== ''" class="text-error heading-7">{{nameErr}}</p>
            </div>
        </div>
      </div>
      <div class=" sticky -bottom-1 bg-white w-full flex justify-end pr-3 pt-3">
        <div class="text-rigth flex gap-2 mb-3">
          <Button :btnSize="'medium'" :textColor="'text1'" :btnColor="'gray1'" :btnText="'Cancel'" @buttonAction="discard()"/>
          <Button :btnSize="'medium'" :textColor="'white'" :btnColor="'success'" :btnText="'Save'" @buttonAction="saveItemsTax()"/>
        </div>
      </div>
    </div>
  </div>
  <ConfirmBox v-if="removeConfirm" :message="'Once deleted cannot undone.'" :title="'Are you sure delete Code?'" />
</div>
</template>
<script>
import ConfirmBox from '@/View/components/ConfirmBox.vue'
import Button from '@/View/components/globalButton.vue'
import TextField from '@/View/components/textfield.vue'
import MyJobApp from '@/api/MyJobApp.js'
export default {
props: ['codeId', 'object'],
components: {
  Button,
  TextField,
  ConfirmBox,
},
data () {
  return {
    CodeNameVal: '',
    removeConfirm: false,
    nameErr: '',
  }
},
 created() {
},
mounted () {
  if (this.codeId > 0) {
    this.CodeNameVal = this.object.codeName
  }
  this.$root.$on('confirmBoxHandler', (response) => {
    document.body.style = 'overflow: visible;'
    if (response) {
        this.deleteApiCall()
      }
      this.removeConfirm = false
  })
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  discard () {
    document.body.style = 'overflow: visible;'
    this.$root.$emit('addEditCodeComp')
  },
  saveItemsTax () {
    let isValid = true
    console.log('this.CodeNameVal', this.CodeNameVal)
    if (this.CodeNameVal.trim() === '') {
      isValid = false
      this.nameErr = 'Expense Category name is requied.'
    }
    if (isValid) {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.AddUpdateAccountingCode(
        this.object.codeId,
        this.CodeNameVal,
        response => {
          this.$root.$emit('addEditCodeComp', true, response.Data)
          document.body.style = 'overflow: visible;'
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        (error) => {
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        }
      )
    }
  },
  deleteApiCall () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      MyJobApp.DeleteCode(
      this.codeId,
      response => {
        console.log('response', response);
        this.$root.$emit('addEditCodeComp', true)
        this.$store.dispatch('SetLoader', {status: false, message: ''})
        this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
      },
      () => {
        this.resetPaginationValues = false
        this.$store.dispatch('SetLoader', {status: false, message: ''})
      }
     )
  },
  removeConfirmCheck () {
    this.removeConfirm = true
  }
},
beforeDestroy () {
  this.$root.$off('confirmBoxHandler')
  // this.$root.$off('closeConfirmBoxDialog')
}
}
</script>
<style scoped>

</style>